import axios from 'axios';
import global from '../../components/Global.vue';

let base = global.serverSite + '/doartIot/doart-modbus';

// 新增点表信息
export const addPointInfo = params => { return axios.post(base + '/pointInfo/addPointInfo', params)};

// 编辑点表信息
export const editPointInfo = params => { return axios.post(base + '/pointInfo/editPointInfo', params)};

// 查询点表列表
export const queryPointInfoList = params => { return axios.post(base + '/pointInfo/queryPointInfoList', params)};

// 查询点表信息详情
export const queryPointInfoDetail = params => { return axios.post(base + '/pointInfo/queryPointInfoDetail', params)};

// 删除点表信息
export const deletePointInfo = params => { return axios.post(base + '/pointInfo/deletePointInfo', params)};
