<template>
    <div>
        <div >
            <el-card shadow="never">
                <div slot="header" class="title">
                    <span>{{title}}</span>
                </div>
                <el-form :inline="true">
                    <el-form-item class="button">
                        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="openAdd">新增
                        </el-button>
                    </el-form-item>
                </el-form>
                <el-table :data="tableData" border size="small" stripe
                          :header-cell-style="{background:'#eef1f6',color:'#606266', textAlign: 'center'}"
                          v-loading="listLoading">
                    <el-table-column type="index" min-width="15px" label="序号" align="center"></el-table-column>
                    <el-table-column prop="name" label="点表名称" min-width="50px" align="center"/>
                    <el-table-column prop="code" label="点表编码" min-width="50px" align="center"/>
                    <el-table-column prop="addressCode" label="设备地址码" min-width="50px" align="center"/>
                    <el-table-column prop="addressType" label="寄存器地址类型" min-width="50px" align="center" :formatter="addressTypeFormat"/>
                    <el-table-column prop="verification" label="指令校验方式" min-width="50px" align="center" :formatter="verificationFormat"/>
                    <el-table-column prop="verifyEndian" label="校验码大小端" min-width="40px" align="center" :formatter="verifyEndianFormat"/>
                    <el-table-column width="80px" label="操作" fixed="right" align="center">
                        <template slot-scope="scope">
                            <el-row>
                                <el-col :span="12">
                                    <el-tooltip effect="dark" content="编辑" placement="top">
                                        <i class="el-icon-edit"  style="font-size: 18px;color: #409EFF;cursor: pointer;vertical-align: middle" @click="openEdit(scope.row)"/>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="12">
                                    <el-tooltip effect="dark" content="删除" placement="top">
                                        <i class="el-icon-delete" style="font-size: 18px;color: #f78989;cursor: pointer;vertical-align: middle" @click="delPoint(scope.row.id)"/>
                                    </el-tooltip>
                                </el-col>
                            </el-row>
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>

            <el-dialog title="新增点表" :visible.sync="addVisible" center :close-on-click-modal="false" width="500px">
                <el-form :model="addForm" :rules="formRules" ref="addForm" label-width="150px" :inline="true">
                    <el-row>
                        <el-form-item label="点表名称：" prop="name">
                            <el-input v-model="addForm.name" placeholder="请输入点表名称" autocomplete="off"
                                      class="optItem"/>
                        </el-form-item>
                    </el-row>
                    <el-row>
                        <el-form-item label="点表编码：" prop="code">
                            <el-input v-model="addForm.code" placeholder="请输入点表编码" autocomplete="off"
                                      class="optItem"/>
                        </el-form-item>
                    </el-row>
                    <el-row>
                        <el-form-item label="设备地址码：" prop="addressCode">
                            <el-input v-model="addForm.addressCode" placeholder="请输入设备地址码" autocomplete="off"
                                      class="optItem"/>
                        </el-form-item>
                    </el-row>
                    <el-row>
                        <el-form-item label="寄存器地址类型：" prop="addressType">
                            <el-select v-model="addForm.addressType" clearable placeholder="请选择寄存器地址类型"
                                       class="optItem">
                                <el-option
                                    v-for="item in addressTypeSelect"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-row>
                    <el-row>
                        <el-form-item label="指令校验方式：" prop="verification">
                            <el-select v-model="addForm.verification" clearable placeholder="请选择指令校验方式"
                                       class="optItem">
                                <el-option
                                    v-for="item in verificationSelect"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-row>
                    <el-row>
                        <el-form-item label="校验码大小端：" prop="addressType">
                            <el-select v-model="addForm.verifyEndian" clearable placeholder="请选择大小端"
                                       class="optItem">
                                <el-option
                                    v-for="item in verifyEndianSelect"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-row>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="addSubmit" :loading="addLoading">确认</el-button>
                    <el-button @click="addVisible = false">取消</el-button>
                </div>
            </el-dialog>
            <el-dialog title="编辑点表" :visible.sync="editVisible" center :close-on-click-modal="false" width="500px">
                <el-form :model="editForm" :rules="formRules" ref="editForm" label-width="150px" :inline="true">
                    <el-row>
                        <el-form-item label="点表名称：" prop="name">
                            <el-input v-model="editForm.name" placeholder="请输入点表名称" autocomplete="off"
                                      class="optItem"/>
                        </el-form-item>
                    </el-row>
                    <el-row>
                        <el-form-item label="点表编码：" prop="code">
                            <el-input v-model="editForm.code" placeholder="请输入点表编码" autocomplete="off"
                                      class="optItem"/>
                        </el-form-item>
                    </el-row>
                    <el-row>
                        <el-form-item label="设备地址码：" prop="addressCode">
                            <el-input v-model="editForm.addressCode" placeholder="请输入设备地址码" autocomplete="off"
                                      class="optItem"/>
                        </el-form-item>
                    </el-row>
                    <el-row>
                        <el-form-item label="寄存器地址类型：" prop="addressType">
                            <el-select v-model="editForm.addressType" clearable placeholder="请选择寄存器地址类型"
                                       class="optItem">
                                <el-option
                                    v-for="item in addressTypeSelect"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-row>
                    <el-row>
                        <el-form-item label="指令校验方式：" prop="verification">
                            <el-select v-model="editForm.verification" clearable placeholder="请选择指令校验方式"
                                       class="optItem">
                                <el-option
                                    v-for="item in verificationSelect"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-row>
                    <el-row>
                        <el-form-item label="校验码大小端：" prop="addressType">
                            <el-select v-model="editForm.verifyEndian" clearable placeholder="请选择大小端"
                                       class="optItem">
                                <el-option
                                    v-for="item in verifyEndianSelect"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-row>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="editSubmit" :loading="editLoading">确认</el-button>
                    <el-button @click="editVisible = false">取消</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import NavBreadcrumb from '../../components/NavBreadcrumb.vue';
import { queryPointInfoList, addPointInfo, editPointInfo, deletePointInfo } from "../../api/modbus/modbusPoint";
import message from "@/utils/message";

export default {

    name: "ModbusPoint",
    components: {NavBreadcrumb},
    props: {

        productModelId: {

            type: Number,
            default: 0,
        },
        title: {

            type: String,
            default: '',
        }
    },
    watch:{

        productModelId(news, olds){

            this.queryPointInfoList();
        }
    },
    data() {
        return {

            listLoading: false,
            tableData: [],

            addLoading: false,
            addVisible: false,
            addForm: {

                productModelId: '',
                name: '',
                code: '',
                addressCode: '',
                addressType: '',
                verification: '',
                verifyEndian: '',
            },
            formRules: {

                name: [
                    {required: true, message: '请输入点表名称', trigger: ['blur', 'change']},
                    { min: 1, max: 30, message: '长度在 1 到 30 个字符', trigger: ['blur', 'change'] },
                    { pattern: /^\S*$/, message: '注意空格', trigger: ['blur', 'change'] }
                ],
                code: [
                    {required: true, message: '请输入点表编码', trigger: ['blur', 'change']},
                    { min: 1, max: 30, message: '长度在 1 到 30 个字符', trigger: ['blur', 'change'] },
                    { pattern: /^\S*$/, message: '注意空格', trigger: ['blur', 'change'] }
                ],
                addressCode: [
                    {required: true, message: '请输入设备地址码', trigger: ['blur', 'change']},
                    { min: 1, max: 2, message: '2位16进制字符串', trigger: ['blur', 'change'] },
                    { pattern: /^[0-9A-F]+$/i, message: '2位16进制字符串', trigger: ['blur', 'change'] }
                ],
                addressType: [
                    {required: true, message: '请选择寄存器地址类型', trigger: ['blur', 'change']},
                ],
                verification: [
                    {required: true, message: '请选择指令校验方式', trigger: ['blur', 'change']},
                ],
                verifyEndian: [
                    {required: true, message: '请选择大小端', trigger: ['blur', 'change']},
                ],
            },

            editLoading: false,
            editVisible: false,
            editForm: {

                id: '',
                productModelId: '',
                name: '',
                code: '',
                addressCode: '',
                addressType: '',
                verification: '',
                verifyEndian: '',
            },

            verifyEndianSelect: [
                {
                    value: 'LITTLE_ENDIAN',
                    label: '小端'
                },
                {
                    value: 'BIG_ENDIAN',
                    label: '大端'
                },
            ],
            verificationSelect: [
                {
                    value: 1,
                    label: 'CRC16-MODBUS'
                },
            ],
            addressTypeSelect: [
                {
                    value: 16,
                    label: '16进制'
                },
                {
                    value: 10,
                    label: '10进制'
                },
            ],
        };
    },
    methods: {

        openAdd() {

            this.addVisible = true;
            this.addForm.productModelId = this.productModelId;
            this.addForm.code = '';
            this.addForm.name = '';
            this.addForm.addressCode = '';
            this.addForm.addressType = 16;
            this.addForm.verification = 1;
            this.addForm.verifyEndian = 'LITTLE_ENDIAN';
            this.$nextTick(() => {

                this.$refs["addForm"].clearValidate();
            });
        },
        openEdit(e) {

            this.editVisible = true;
            this.editForm.productModelId = this.productModelId;
            this.editForm.code = e.code;
            this.editForm.name = e.name;
            this.editForm.id = e.id;
            this.editForm.addressCode = e.addressCode;
            this.editForm.addressType = e.addressType;
            this.editForm.verification = e.verification;
            this.editForm.verifyEndian = e.verifyEndian;
        },
        addSubmit() {

            this.$refs["addForm"].validate((valid) => {

                if (valid) {

                    this.addLoading = true;
                    addPointInfo(this.addForm).then((res) => {

                        this.addLoading = false;

                        if (res.code === 200) {

                            this.addVisible = false;
                            this.queryPointInfoList();
                            message.success(res.msg);
                            this.$emit('refreshTree');
                        } else {

                            message.error(res.msg)
                        }

                    }).catch(err => {

                        console.error(err);
                    });
                } else {

                    return false;
                }
            });
        },
        editSubmit() {

            this.$refs["editForm"].validate((valid) => {

                if (valid) {

                    this.editLoading = true;
                    editPointInfo(this.editForm).then((res) => {

                        this.editLoading = false;

                        if (res.code === 200) {

                            this.editVisible = false;
                            this.queryPointInfoList();
                            message.success(res.msg);
                            this.$emit('refreshTree');
                        } else {

                            message.error(res.msg)
                        }

                    }).catch(err => {

                        console.error(err);
                    });
                } else {

                    return false;
                }
            });
        },
        delPoint(id) {

            message.confirm("是否要删除该条点表信息？", false, "warning", ()=>{

                deletePointInfo({id: id, productModelId: this.productModelId}).then((res) => {

                    if (res.code === 200) {

                        this.queryPointInfoList();
                        message.success(res.msg);
                        this.$emit('refreshTree');
                    } else {

                        message.error(res.msg);
                    }
                }).catch(err=> console.log(err));
            })
        },
        /**
         * 查询页面数据.
         */
        queryPointInfoList() {

            this.listLoading = true;
            queryPointInfoList({id: this.productModelId}).then((res) => {

                this.listLoading = false;
                if (res.code === 200) {

                    this.tableData = res.data;
                }
            }).catch(err => {

                    console.error(err)
                    this.listLoading = false;
                }
            );
        },
        verificationFormat(row, column, cellValue) {

            if (cellValue === 1) {

                return 'CRC16-MODBUS';
            } else {

                return '未知';
            }
        },
        verifyEndianFormat(row, column, cellValue) {

            if (cellValue === 'BIG_ENDIAN') {

                return '大端';
            } else if (cellValue === 'LITTLE_ENDIAN') {

                return '小端';
            } else {

                return '未知';
            }
        },
        addressTypeFormat(row, column, cellValue) {

            return cellValue + '进制'
        },
    },
    mounted() {

        this.queryPointInfoList();
    },
}
</script>

<style scoped>

.title {

    font-weight: bolder;
    font-size: larger;
}

.optItem {

    width: 250px;
}

.button {

    margin: 0 10px 20px 0;
}
</style>
